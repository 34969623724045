import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Auth0PageProps {
  onUpdate?: () => void;
}

function Auth0Page({ onUpdate }: Auth0PageProps) {
  const navigate = useNavigate();
  const [message, setMessage] = useState('Completing login, please wait...');

  useEffect(() => {
    console.log("Auth0Page: Processing login");
    
    const completeLogin = async () => {
      // Get the return URL from sessionStorage, or default to '/'
      const returnTo = sessionStorage.getItem('loginReturnUrl') || '/';
      console.log(`Auth0Page: Redirecting to ${returnTo}`);
      
      // Clear the stored return URL
      sessionStorage.removeItem('loginReturnUrl');
      
      // Call onUpdate if provided
      if (onUpdate) {
        await onUpdate();
      }
      
      // Update message before redirecting
      setMessage('Login completed. Redirecting...');
      
      // Redirect to the return URL after a short delay
      setTimeout(() => {
        navigate(returnTo, { replace: true });
      }, 1000); // 1 second delay
    };

    completeLogin();
  }, [navigate, onUpdate]);

  return (
    <div className="page">
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {message}
        </div>
      </div>
    </div>
  );
}

export default Auth0Page;